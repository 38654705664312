import { ExtendCSS, View } from 'vcc-ui';

const GridItem: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => <View extend={gridItemStyle}>{children}</View>;

const gridItemStyle: ExtendCSS = {
  '& > *': { marginTop: '0 !important' },
};

export default GridItem;
