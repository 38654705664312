import { ExtendCSS, View } from 'vcc-ui';

import GridItem from './GridItem';

export type GridProps = {
  numberOfColumns: number;
  children: React.ReactNode;
};

const Grid: React.FC<GridProps> = ({ numberOfColumns, children }) => (
  <View extend={gridStyle(numberOfColumns)}>{children}</View>
);

const gridStyle =
  (numberOfColumns: number): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: baselineGrid * 4,
    columnGap: baselineGrid * 4,

    fromM: {
      gridTemplateColumns: '1fr 1fr',
      rowGap: baselineGrid * 5,
      columnGap: baselineGrid * 3,
    },

    fromL: { gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)` },
  });

export default Grid;

export { GridItem };
